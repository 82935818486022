<template>
  <div class="nk-app-root">
        <!-- main @s -->
        <div class="nk-main ">
            <!-- wrap @s -->
            <div class="nk-wrap nk-wrap-nosidebar">
                <!-- content @s -->
                <div class="nk-content ">
                    <div class="nk-block nk-block-middle nk-auth-body  wide-xs">
                        <div class="card card-bordered">
                            <div class="card-inner card-inner-lg">
                                <div class="brand-logo pb-4 text-center">
                                    <img class="logo" src="@/assets/images/logocolor.png" alt="logo-dark">
                                </div>
                                <form @submit.prevent="send">
                                    <div class="form-group">
                                        <div class="form-label-group">
                                            <label class="form-label">Usuario o Correo Electrónico</label>
                                        </div>
                                        <input type="text" required class="form-control form-control-lg" placeholder="Ingresa tu usuario o correo electrónico" v-model="form.username" autocomplete="username">
                                    </div>
                                    <div class="form-group">
                                        <div class="form-label-group">
                                            <label class="form-label" for="password">Contraseña</label>
                                        </div>
                                        <div class="form-control-wrap">
                                            <a @click.prevent="verPass = !verPass" class="form-icon form-icon-right passcode-switch" data-target="password">
                                                <em v-if="!verPass" class="icon ni ni-eye"></em>
                                                <em v-if="verPass" class="icon ni ni-eye-off"></em>
                                            </a>
                                            <input required :type="(verPass) ? 'text' : 'password'" class="form-control form-control-lg" id="password" placeholder="Ingresa tu contraseña" v-model="form.pass" autocomplete="current-password">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button class="btn btn-lg btn-primary btn-block">Iniciar Sesión</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="nk-footer nk-auth-footer-full">
                        <div class="container wide-lg">
                            <div class="row g-3">
                                <div class="col-lg-12">
                                    <div class="nk-block-content text-center">
                                        <p class="text-soft">© {{year}} | Hecho con <i class="ni ni-coffee-fill text-blue"></i> y <i class="ni ni-heart-fill text-danger"></i> en <a href="https://tekoestudio.com" class="text-blue" target="_blank">Teko Estudio</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- wrap @e -->
            </div>
            <!-- content @e -->
        </div>
        <!-- main @e -->
    </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import useAuth from '@/providers/auth.ts';
import Swal from 'sweetalert2';
import { useApi } from "@/use/useApi";

export default {
  name: 'Login',
  setup(){
    //Router
    const router = useRouter();
    const { login } = useAuth();
    //Data
    const year = ref(new Date().getFullYear());
    const verPass = ref(false);
    const { loading, make } = useApi();

    const form = ref({
        username: '',
        pass: ''
    });

    async function send(){
      loading.message = 'Validando información';
      let { data } = await make('users/login', form.value);
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          //Auth Login
          login(data.token, data.user);
          //Actualizar
          router.go();
        }
      }
    }

    return { year, verPass, loading, form, send };
    }
}
</script>

<style lang="scss" scoped>
    .logo {
        height: 100px;
    }
    .corazon {
        color: darkred;
    }
</style>